
import { Link } from "react-router-dom";
import { BrowsingOptions } from "../components/ui/BrowsingOptions";

const MessageReceived = () => {
    return (
        <div className="page-content">
            <h1 style={{'text-align' : 'center'}}>Thank you for your message!</h1>
            <p style={{'text-align' : 'center'}}>I'll get back to you when I go through my messages next time. Feel to to browse the site until then - for instance the map or my blog posts.</p>

            {BrowsingOptions()}


        </div>
    );
};

export default MessageReceived;
