import React from 'react'
import Spinner from '../ui/Spinner'
import BlogItem from '../blog/BlogItem'

const BlogGrid = ({ items, isLoading, categories }) => {
  const selectedCategories = [];

  for (let i = 0; i < categories.length; i++) {
    selectedCategories.push(categories[i].value);
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <section className='cards'>
      {items.map((item) => (
        <BlogItem key={item.id} item={item} categories={selectedCategories}></BlogItem>
      ))}
    </section>
  )
}

export default BlogGrid