import SeeMapBanner from "../components/map/SeeMapBanner";
import Timeline from "../components/timeline/Timeline";
import LatestBlogPosts from "../components/LatestBlogPosts";
import FrontpageFeaturedImage from "../components/ui/FrontpageFeaturedImage";
import { Link } from "react-router-dom";

export default function Homepage() {
  return (
    <div className="frontpage">
      <FrontpageFeaturedImage />

      <div className="container fp-section">
        <div className="row">
          <div className="col-lg-4">
            <h3 className="frontpage-tagline">Welcome to the bike blog...</h3>
            <p>
              ...a blog about long distance cycling and adventuring. 
              I've biked around the world 2022, where I took pictures, wrote posts, shared experiences and saved waypoints along the way. In 2023, I rode for Copenhagen to the North Cape.
            </p>
            <p className="initial-content">
            You can see my latest waypoints <Link to="/waypoints">here</Link>, or read posts from my <Link to="/posts">journey</Link>. 
            You can also check out a <Link to="/map">map</Link> that tracks my journies. The aim of this site is to serve both as a journal for my trip 
            and to keep friends and family posted, as well as a guide and inspiration for others to do the same.
            </p>
          </div>
          <div className="col-lg-8">
            <div className="timeline-container">
              <Timeline starting_points={9} />
            </div>
          </div>
        </div>
      </div>
      <SeeMapBanner />
      <div className="container fp-section">
        <div className="row">
          <div className="col-md-7 centered-middle mb-4">Get inspired!</div>
          <div className="col-md-7 centered">
          In the blog posts you can find information about my planning process, route considerations, bikes and setup, 
          and of course plenty of posts about my experiences on the road – the places I see and the people I meet. 
            <br />
            <br /> 
            Hopefully, this may inspire you to start dreaming of your own
            bikepacking trip or even to start planning it. Bikepacking is a
            simple, cheap and flexible way of travelling and seeing the world in
            a different pace.
          </div>
        </div>
      </div>
      <LatestBlogPosts amount={3} />
    </div>
  );
}
