import axios from "axios"
import { useState, useEffect } from "react";

export default function useBlogSearch (query, filter, pageNumber, pageSize) {
    const [items, setItems] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    var filterQuery = '';
    if (filter !== undefined & filter !== '') {
      filter.forEach(element => {
        if (filterQuery === '') {
          filterQuery = '&_where[_or][0][blog_tags.tag_in]=' + element.label;
        } else  {
          filterQuery = `${filterQuery}&_where[_or][0][blog_tags.tag_in]=${element.label}`;
        }
      });
    }


    useEffect(() => {
        const fetchItems = async () => {
          setIsLoading(true)  
          const result = await axios.get(
            `https://content.bikepackingstories.com/blogs?title_contains=${query}${filterQuery}&_sort=date:DESC&_start=${(pageNumber-1)*pageSize}&_limit=${pageSize}`
          )
          setItems(result.data)
          setIsLoading(false)
        }
    
        fetchItems()
      }, [query, pageNumber, filter, filterQuery, pageSize])
    return [isLoading, items];
}