import { useQuery, gql } from "@apollo/client";
import Spinner from "../ui/Spinner";

const FOOTERLINKS = gql`
  query GetFooterLinks {
    footerQuickLinks {
      id
      displayName
      url
    }
  }
`;

const FooterLinks = () => {
  const { loading, error, data } = useQuery(FOOTERLINKS);

  if (error) return <p>Error loading footer links</p>;

  return loading ? (
    <Spinner />
  ) : (
    <ul className="footer-links">
      {data.footerQuickLinks.map((link) => (
        <li key={link.id}>
          <a href={link.url}>
            {link.displayName}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default FooterLinks;
