import { useState } from "react";
import {Link} from "react-router-dom";

const MobileNavbar = () => {
    const [isMenuOpen, setisMenuOpen] = useState(false);
    
    function handleSubmit(e) {
      document.getElementById("root").style.overflowX = null;      
      document.getElementById("root").style.overflowX = "hidden";
      setisMenuOpen(!isMenuOpen)
    }

    return ( <nav id="nav" class="nav" role="navigation" className={isMenuOpen ? "nav--open" : ""}>
      
    {isMenuOpen &&   
    <ul className={"nav__menu"} id="menu" tabindex="-1" aria-label="main navigation" hidden={!isMenuOpen}>
      <li className="nav__item">
        <Link to="/" className="nav__link" onClick={handleSubmit}>Home</Link>
      </li>
      <li className="nav__item">
        <Link to="/map" className="nav__link" onClick={handleSubmit}>Map</Link>
      </li>
      <li className="nav__item">
        <Link to="/posts" className="nav__link" onClick={handleSubmit}>Blog</Link>
      </li>
      <li className="nav__item">
        <Link to="/waypoints" className="nav__link" onClick={handleSubmit}>Waypoints</Link>
      </li>
      <li className="nav__item">
        <Link to="/our-story" className="nav__link" onClick={handleSubmit}>Our Story</Link>
      </li>
    </ul>
    }
    
    
    <a href="#nav" className="nav__toggle" role="button" aria-expanded={isMenuOpen} aria-controls="menu" onClick={handleSubmit}>
      <svg className="menuicon" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
        <title>Toggle Menu</title>
        <g>
          <line className="menuicon__bar" x1="13" y1="16.5" x2="37" y2="16.5"/>
          <line className="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5"/>
          <line className="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5"/>
          <line className="menuicon__bar" x1="13" y1="32.5" x2="37" y2="32.5"/>
          <circle className="menuicon__circle" r="23" cx="25" cy="25" />
        </g>
      </svg>
    </a>
    
    
    <div className="splash"></div>
    
  </nav> );
}
 
export default MobileNavbar;