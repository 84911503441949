import * as React from 'react';
import { useState } from 'react';
import ReactMapGL, {Marker, Popup} from 'react-map-gl';
import {Room} from '@material-ui/icons';
import ReactMarkdown from 'react-markdown';
import {useQuery, gql} from '@apollo/client';
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';


function getParsedDate(date){
  var dateObj = String(date).split('-');
  var day = ("0" + dateObj[2]).substr(-2);
  var month = ("0" + dateObj[1]).substr(-2);
  return day + '-' + month + '-' + dateObj[0];
}


function Map() {   
           
  
  <Helmet>
    <meta charSet="utf-8" />
    <title>Bikepackingstories | Map</title>
    <link rel="canonical" href="http://biekpackingstories.com" />
  </Helmet>

  const [currentLatitude, setCurrentLatitude] = useState(55.6);
  const [currentLongitude, setCurrentLongitude] = useState(12.5);
  const [currentZoom, setCurrentZoom] = useState(2);

  async function UpdateCoordinates() 
  {
    const result = await axios.get(`https://content.bikepackingstories.com/config`);
    setCurrentLatitude(result.data['current_lat']);
    setCurrentLongitude(result.data['current_long']);
    setCurrentZoom(result.data['current_zoom']);
  }
  UpdateCoordinates();

  let newDate = new Date(new Date().setDate(new Date().getDate()));
  let limitDate = newDate;

  let queryLimitDate = `${limitDate.getFullYear()}-${(limitDate.getMonth() +1)<10?`0${(limitDate.getMonth() +1)}`:`${(limitDate.getMonth() +1)}`}-${(limitDate.getDate())<10?`0${(limitDate.getDate())}`:`${(limitDate.getDate())}`}`; 

  const WAYPOINTS = gql`
  query GetWaypoints {
    waypoints(sort: "date:desc" where: {date_lte: "${queryLimitDate}"}) { 
      title
      latitude
      longitude
      description
      subheading
      distance
      date
      id
      images {
        formats
        url
        id
        previewUrl
      }
      adventure {
        adventure
        marker_color
      }
    }
  }`

// const [datapoints, setDatapoints] = useState([]);

// async function UpdateDatapoints() {
//   const resp = await axios.get('https://cutcard.net/api/bikepackingstories/currentwaypoints');
//   setDatapoints(resp);
// }



// const FetchWaypoints = async () => {
//   try {
//     const resp = await axios.get('https://cutcard.net/api/bikepackingstories/currentwaypoints');
//     return resp.data;
//   } catch (e) {
//     return [];
//   }
// }; 



const [viewport, setViewport] = useState({
    width: "100%",
    height: "90vh",
    latitude: currentLatitude,
    longitude: currentLongitude,
    zoom: currentZoom
  });

  //const [showPopup, togglePopup] = React.useState(false);
  const [currentPlaceId, setCurrentPlaceId] = useState(null);
  const { loading, error, data } = useQuery(WAYPOINTS)


  if (loading) return <p>Loading...</p>
  if (error) return <p>Error loading page</p>


    const handleMarkerClick = (id, lat, long) => {
        setCurrentPlaceId(id);
        setViewport({ ...viewport, latitude: lat, longitude: long });
      };
    return (
    <div className="map-container">
<p>{queryLimitDate}</p>
    <ReactMapGL
      {...viewport}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX}
      mapStyle="mapbox://styles/andreasmarkussen/ckrafizh54l1917o1c6ygoarn"
      onViewportChange={(viewport) => setViewport(viewport)}>

{data.waypoints.map((p) => (
          <>
            <Marker
              latitude={p.latitude}
              longitude={p.longitude}
              offsetLeft={-3.5 * viewport.zoom}
              offsetTop={-7 * viewport.zoom}
              className="map-marker"
            >
              <Room
                style={{
                  fontSize: 7 * viewport.zoom,
                  color: p.adventure !== null ? p.adventure.marker_color : "slateblue",
                  cursor: "pointer",
                }}
                onClick={() => handleMarkerClick(p.id, p.latitude, p.longitude)}
              />
            </Marker>
            {p.id === currentPlaceId && (<Popup 
            key={p.id}
            latitude={p.latitude}
            longitude={p.longitude}
            closeButton={true}
            closeOnClick={false}
            onClose={() => setCurrentPlaceId(null)}
            anchor="left">

                <div className="marker-card">
                    <h4>{p.title}</h4>
                    <div className="metadata">
                  <p className="item">Distance: {p.distance}km</p>
                  <p className="item">Date: {getParsedDate(p.date)}</p>
                  </div>
                  <hr  style={{
            width: "100%" 
        }}/>
                  
                  {p.subheading !== null && 
                  <ReactMarkdown className='map-md'>{p.subheading}</ReactMarkdown>
                  }
                  
                  {p.images.length !== 0 ? 
                  <Link to={'/waypoint/' + p.id} className="map-see-more push-clickme-up">See post</Link> : 
                  <Link to={'/waypoint/' + p.id} className="map-see-more">See post</Link>
                  }
                  </div>
                  {p.images.length !== 0 && <img className="popup-image" src={"https://content.bikepackingstories.com" + p.images[0]["formats"]["medium"]["url"]} alt="Featured popup"/>}
            </Popup>)}

            </>
              

      ))}
      </ReactMapGL></div>
  );
}

export default Map
