import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import BPS_map_thumbnail from '../../img/BPS_map_thumbnail.png'
import Spinner from "./Spinner";

export const BrowsingOptions = () => {
    const [randomImage, setRandomImage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    useEffect(() => {
        const fetchRandomImage = async () => {
            try {
                const response = await fetch("https://content.bikepackingstories.com/waypoints");
                const data = await response.json();
                const randomIndex = Math.floor(Math.random() * data.length);
                const randomImage = data[randomIndex]['images'][0]['formats']['medium']['url'];
                setRandomImage('https://content.bikepackingstories.com' + randomImage);
                setIsLoading(false);
                setIsImageLoaded(true);
            } catch (error) {
                console.error("Error fetching random image:", error);
            }
        };

        fetchRandomImage();

        const intervalId = setInterval(fetchRandomImage, 5000); // Update every 5 seconds

        return () => {
            clearInterval(intervalId); // Clean up the interval on component unmount
        };
    }, []);

    return (
        <div className="container fp-section">
            <div className="row">
                {/* Map card */}
                <div className="col-md">
                <article className="recipe">
                    <div className="pizza-box">
                        <img
                            src={BPS_map_thumbnail} alt='Go to map'
                        />

                    </div>
                    <div className="">
                        <p className="recipe-desc px-4">I've logged every day on my bike tour and combined them to make a comprehensive day-by-day map. It's my interactive journal and open for all to see.</p>

                    </div>
                    <div className="waypoint-btn btn browse-content-btn mb-4">
                        See post
                    </div>
                </article>
                </div>

                
                    
                {/* pOSTS card */}
                <div className="col-md">
                <article className="recipe">
                    <div className="pizza-box fadein-img">
                    {isLoading ? (
                            <Spinner style={{ width: '100%', height: '100%' }} />
                        ) : (
                            <img
                                className={`card-img-top`}
                                src={randomImage}
                                alt="Go to posts"
                                style={{ 'object-fit': 'cover' }}
                                onLoad={() => setIsImageLoaded(true)}
                            />
                        )}
                        {/* <img className="fadein-img"
                            src={BPS_map_thumbnail} width="1500" height="1368" alt='Go to map'
                        /> */}

                    </div>
                    <div className="">
                        <p className="recipe-desc px-4">I've written some medium-length posts about various elements of bikepacking. These are surface-level, honest write-ups about my impressions pedaling about.</p>

                    </div>
                    <Link to="/posts" className="waypoint-btn btn browse-content-btn mb-4">Go to blogposts</Link>
                </article>
                </div>
            </div>
        </div>
    );
};
