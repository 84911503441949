import React from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

function getParsedDate(date){
  var dateObj = String(date).split('-');
  var day = ("0" + dateObj[2]).substr(-2);
  var month = ("0" + dateObj[1]).substr(-2);
  return day + '-' + month + '-' + dateObj[0];
}

const BlogItem = ({ item, categories }) => {
  const imageUrl =
    "url(" +
    (item.featured_image.formats.medium
      ? "https://content.bikepackingstories.com" + item.featured_image.formats.medium.url
      : "https://content.bikepackingstories.com" + item.featured_image.url) +
    ") center no-repeat";

  const assignedCategories = [];
  item.blog_tags.forEach((tag) => {
    assignedCategories.push(tag.tag);
  });

  return (
     (
      <div className="blog-card spring-fever" style={{ background: imageUrl }}>
        <div className="title-content">
          <h3>
            <Link to={"/post/" + item.id}>{item.title}</Link>
          </h3>
        </div>
        <div className="card-info">
          <ReactMarkdown>
          {(item.subheading !== null) ? item.subheading : item.content.substring(0, 150) + "..."}
          </ReactMarkdown>
          <Link to={"/post/" + item.id}>
            Read Article<span className="licon icon-arr icon-black"></span>
          </Link>
        </div>
        <div className="utility-info">
          <ul className="utility-list">
            <li>
              <span className="licon icon-dat"></span>
              {getParsedDate(item.date)}
            </li>
            <li>
              <span className="licon icon-tag"></span>
              {item.blog_tags.map((tag, i) => (
                <a key={tag.id} href={"posts?" + tag.tag}>
                  {tag.tag}
                  {i !== item.blog_tags.length - 1 && ", "}
                </a>
              ))}
            </li>
          </ul>
        </div>
        <div className="color-overlay"></div>
      </div>
    )
  );
};

export default BlogItem;
