import { Link } from "react-router-dom";
import mainLogo from './mainLogo192.png'; 
import React from "react";

export default class WebNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.listener = null;
    this.state = {
      status: "top"
    };
  }

  componentDidMount() {
    this.listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 120) {
        if (this.state.status !== "amir") {
          this.setState({ status: "amir" });
        }
      } else {
        if (this.state.status !== "top") {
          this.setState({ status: "top" });
        }
      }
    });
  }


  componentDidUpdate() {
    document.removeEventListener("scroll", this.listener);
  }

    render() {return ( <div className={this.state.status === "top" ? "navbar" : "navbar scrolled"}>
    <Link to="/">
      {/* <h4 className="nav-home-text">Home</h4> */}
      <img src={mainLogo} alt="mainLogo" className="nav-home-logo" />
    </Link>
    <Link to="/map" className="navbar-option">
      <h4>Map</h4>
    </Link>
    <Link to="/posts" className="navbar-option">
      <h4>Blog</h4>
    </Link>
    <Link to="/waypoints" className="navbar-option">
      <h4>Waypoints</h4>
    </Link>
    <Link to="/our-story" className="navbar-option">
      <h4>About</h4>
    </Link>
  </div> );
  }
}