import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

// https://codepen.io/JavaScriptJunkie/details/WgRBxw

const formatDate = (date) => {
    const dateArray = date.split('-');
    const formattedDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
    return formattedDate;
}

const TimelineItem = (timelineObject) => { 
    var descText = '';
    if (timelineObject.timelineObject.description) {
        if (timelineObject.timelineObject.description.length > 140) {
            descText = timelineObject.timelineObject.description.substring(0, 140) + '...';
        } else {
            descText = timelineObject.timelineObject.description;
        }
    }
    
    return(
    <div className="timeline-item">
        <div className="timeline-item-content">
            <h3>{timelineObject.timelineObject.title}</h3>
            <time>{formatDate(timelineObject.timelineObject.date)}</time>    
            <ReactMarkdown className="timeline-mk-item">{descText}</ReactMarkdown>
            {timelineObject.timelineObject.id && (
                <Link to={"waypoint/" + timelineObject.timelineObject.id}>See description</Link>
            )}
            <span className="circle" />
        </div>
    </div>
)}

const Timeline = ({starting_points}) => {
    const limitDate = new Date(new Date().setDate(new Date().getDate() - 7));
    let queryLimitDate = `${limitDate.getFullYear()}-${(limitDate.getMonth() +1)<10?`0${(limitDate.getMonth() +1)}`:`${(limitDate.getMonth() +1)}`}-${(limitDate.getDate())<10?`0${(limitDate.getDate())}`:`${(limitDate.getDate())}`}`; 

        const WAYPOINTS = gql`
            query GetWaypoints {
            waypoints(start: 0 , limit:${starting_points}, sort: "date:desc" where: { date_lte: "${queryLimitDate}" }) {
                latitude
                longitude
                description
                distance
                date
                title
                id
                blog {
                id
                content
                title
                }
                images {
                previewUrl
                id
                previewUrl
                }
            }
            }`
    const { loading, error, data } = useQuery(WAYPOINTS)
    
    if (loading) return <p>Loading...</p>
    if (error) return <p>Error loading timeline</p>

    return ((
        <div className="timeline-container" id="frontpage-timeline">
            {data.waypoints.map((waypoint) => (
                <TimelineItem timelineObject={waypoint} key={waypoint.id} />
            ))}
        </div>  
    ))
            }


export default Timeline;