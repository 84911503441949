// const CategoryFilter = ({ getQuery, getCategory, category }) => {
import React from "react";
import Select from "react-select";
import { useQuery, gql } from "@apollo/client";

const BLOGTAGS = gql`
  query GetBlogTags {
    blogTags {
      id
      tag
    }
  }
`;

const CategoryFilter = ({ getQuery, getText, text }) => {
  const { loading, error, data } = useQuery(BLOGTAGS);

  const onChange = (q) => {
    getText(q);
    getQuery(q);
  };

  var options = [];

  if (data !== undefined) {
    data.blogTags.forEach((tag) => options.push({ value: tag.tag, label: tag.tag }));
  }

  if (error) return <p>Error loading categories dropdown</p>;
  if (loading) return <p>Loading...</p>;

  if (data !== undefined) {
    return (
      <Select
        isMulti
        name="categories"
        options={options}
        className="basic-multi-select"
        onChange={(e) => onChange(e)}
        classNamePrefix="select"
        value={text}
        placeholder="Filter by category"
      />
    );
  } else {
    return <div>loading...</div>;
  }
};

export default CategoryFilter;
