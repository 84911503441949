import { useHistory } from 'react-router-dom';
const ContactForm = () => {
    const history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault();
        // Perform form submission logic here
        const formData = new FormData(event.target);
        const formDataObj = Object.fromEntries(formData.entries());
        console.log(formDataObj);
        // Send the form data to the API
        fetch('https://cutcard.net/api/bikepackingstories/post_email_args', {
            method: 'POST',
            body: JSON.stringify(formDataObj),
        });

        // Redirect to /message-received
        history.push('/message-received');
    };

    return (
        <div className="contact-container">
            <form id="contact-form" method="POST" action="https://cutcard.net/api/bikepackingstories/post_email_args" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" name="name" placeholder="Name..." />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input type="email" className="form-control" name="email" aria-describedby="emailHelp" placeholder="Email..." />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea className="form-control" rows="5" name="content" placeholder="Type here..."></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
}

export default ContactForm;


