import Search from '../components/ui/Search'
import React, { useState} from 'react'
import BlogGrid from '../components/blog/BlogGrid';
import Spinner from '../components/ui/Spinner';
import CategoryFilter from '../components/ui/CategoryFilter';
import useBlogSearch from '../hooks/useQuery';

// Searchable query: https://content.bikepackingstories.com/blogs?_where[_or][0][title_contains]=title2&_where[_or][1][content_contains]=s

function BlogPosts() {

  const [query, setQuery] = useState('');
  const [text, setText] = useState('');

  const [categoryQuery, setCategoryQuery] = useState('');
  const [categories, setCategories] = useState(''); 

  const [isLoading, items] = useBlogSearch(text, categories, 1, 90)

  if (isLoading) return ( <div className='page-content'>
                            <div className="container filters">
                              <div className="row">
                                <div><Spinner /></div>
                              </div>
                            </div>
                          </div>)

  return (
        <div className='page-content'>
        <div className="container filters">
          <div className="row">
            <div className="col-12 blog-intro">
              <h2>Blog posts</h2>
              <p>Here's a collection of some of the stories I've written about our travels, and adventures I've had. 
                The blog posts explore gear, food and the general mood of a bikepacking trip. </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-8 search-category-container">
            <CategoryFilter getQuery = {(q) => setCategoryQuery(q)} getText={(t) => setCategories(t)} text={categories} />
            </div>
          </div>
          <BlogGrid isLoading={isLoading} items={items} categories = {categories}/>
        
        </div>
        </div>
      );

    }
 
export default BlogPosts;
