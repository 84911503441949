import {useQuery, gql} from '@apollo/client';
import FooterLinks from "./FooterLinks";
import Spinner from "../ui/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const FOOTERTEXT = gql`
query GetFooterText {
  footerAboutText {
    description
  }
}`


const Footer = () => {
    const { loading, error, data } = useQuery(FOOTERTEXT)
    var footerDescription = null;
    if (error || data === null || data === undefined) {
      footerDescription = null;
    } else {
      try {
        footerDescription = data.footerAboutText.description; 
      } catch (error) {
        footerDescription = null;
      }
    }

    footerDescription = "A blog about a long bikepacking journey. I write about experiences and learning to pack and unpack on two wheels. Feel free to follow along";

    return ( loading ? <Spinner /> :
      <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h6>About</h6>
            <p className="text-justify">{footerDescription}</p>
          </div>

          <div className="col-xs-6 col-md-3">
            <h6></h6>
            <ul className="footer-links">
            </ul>
          </div>

          <div className="col-xs-6 col-md-3">
            <h6>Quick Links</h6>
            {!error && <FooterLinks />}
          </div>
        </div>
        <hr></hr>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text">Copyright &copy; {new Date().getFullYear()} All Rights Reserved.
            </p>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              <li><a className="contact-btn" href="https://bikepackingstories.com/contact"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></a></li>
              <li><a className="dribbble" href="https://www.instagram.com/bikepackingstories_com/"><FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon></a></li>
            </ul>
          </div>
        </div>
      </div>
</footer>);
}
 
export default Footer;