import React from "react";
import MobileNavbar from "./MobileNavbar";
import WebNavbar from "./NavOptions";
import CurrentLocation from "./currentLocation";

export default function SiteHeader({ title, subtitle, isMap = false }) {
  var type = null;

  if (isMap) {
    type = "site-header map-header";
  } else {
    type = "site-header non-map-header";
  }

  if (isMap) {
    return (
      <div className={type}>
        <WebNavbar />
        
        <CurrentLocation />
        
        <MobileNavbar />
      
      </div>
    );
  } else {
    return (
      <div className={type}>

        <WebNavbar />

        <CurrentLocation />
      
        <MobileNavbar />

        <h1 className="title">{title}</h1>
      </div>
    );
  }
}