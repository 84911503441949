import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client"
import {Helmet} from "react-helmet";
import ReactGA from 'react-ga4';






// page & layout imports
import Homepage from './pages/Homepage'
import BlogPost from './pages/BlogPost'
import Footer from './components/metaComponents/SiteFooter'
import SiteHeader from "./components/metaComponents/SiteHeader"
import Map from './pages/Map'
import BlogPosts from './pages/Blog'
import About from './pages/About'
import Waypoints from './pages/Waypoints'
import OurStory from './pages/OurStory'
import Contact from './pages/Contact'
import Waypoint from './pages/Waypoint'
import TestPage from './pages/TestPage'
import TagManager from 'react-gtm-module'
import ScrollToTop from './components/utilities/ScrollToTop'
import MessageReceived from './pages/MessageReceived'
import Error404 from './pages/errorPages/404';


const TRACKID = 'G-K3BFZWL3ME';
ReactGA.initialize(TRACKID);

ReactGA.send('pageview')
ReactGA.send({ hitType: 'pageview', page:  '/'})



// apollo client
const client = new ApolloClient({
  uri: 'https://content.bikepackingstories.com/graphql',
  cache: new InMemoryCache()
})

// Init GTM
const tagManagerArgs = {
    gtmId: 'GTM-WMGL5T5'
}

TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <Router>
      <ScrollToTop />
      <ApolloProvider client={client}>
        <div className="App">
          <Switch>
            <Route exact path="/">
            <Helmet>
              <meta charSet="utf-8" />
              <title>Bikepackingstories</title>
            </Helmet>
            <SiteHeader title="The Bicycle Blog" subtitle="Around the world" isMap={true}/>
              <Homepage/>
            </Route>
            
            
            
            <Route path="/posts">
            <Helmet>
              <meta charSet="utf-8" />
              <title>Bikepackingstories | Posts</title>
            </Helmet>
	  
            <SiteHeader title="Blog posts" subtitle="Around the world" isMap={true}/>
	  <BlogPosts />
            </Route>
            
            <Route path="/about">
            <SiteHeader title="The Bicycle Blog" subtitle="Around the world" isMap={true}/>
              <About />
            </Route>
            
            
            <Route path="/post/:id">
            <SiteHeader title="Blog posts" subtitle="Around the world" isMap={true}/>
              <BlogPost />
            </Route>
            
            <Route path="/map">            
            <Helmet>
              <meta charSet="utf-8" />
              <title>Bikepackingstories | Map</title>
            </Helmet>
            <SiteHeader isMap={true}/>
              <Map />
            </Route>
            
            <Route path="/waypoints">
            <Helmet>
              <meta charSet="utf-8" />
              <title>Bikepackingstories | Waypoints</title>
            </Helmet>
            <SiteHeader title="Waypoints" subtitle="Around the world" isMap={true}/>
              <Waypoints />
            </Route>
            
            
            <Route path="/waypoint/:id">
            <SiteHeader title="Waypoint details" subtitle="Around the world" isMap={true}/>
              <Waypoint />
            </Route>
            
            <Route path="/our-story">
            <Helmet>
              <meta charSet="utf-8" />
              <title>Bikepackingstories | About</title>
            </Helmet>
            <SiteHeader title="Our story" subtitle="Around the world" isMap={true}/>
              <OurStory />
            </Route>

            <Route path="/contact">
            <Helmet>
              <meta charSet="utf-8" />
              <title>Bikepackingstories | Reach Out</title>
            </Helmet>
            <SiteHeader title="Reach out" subtitle="Around the world" isMap={true}/>
              <Contact />
            </Route>

            <Route path="/message-received">
            <Helmet>
              <meta charSet="utf-8" />
              <title>Bikepackingstories | Message Recieved</title>
            </Helmet>
            <SiteHeader title="We've recieved your message" subtitle="Around the world" isMap={true}/>
              <MessageReceived />
            </Route>

            <Route path="*">
            <Helmet>
              <meta charSet="utf-8" />
              <title>Bikepackingstories | Page not found</title>
              <meta name="Page not found" content="Error 404" />
            </Helmet>
              <SiteHeader title="Page not found" subtitle="Around the world" isMap={true}/>
              <Error404 />
            </Route>



          </Switch>
          <Footer />
        </div>
      </ApolloProvider>
    </Router>
  );
}

export default App
