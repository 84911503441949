import { BrowsingOptions } from "../../components/ui/BrowsingOptions";

const Error404 = () => {
    return (
      <div className="page-content">
        <h1 style={{'text-align' : 'center'}}>404 - Page not found</h1>
        <p style={{'text-align' : 'center'}}>Sorry, this particular page does not exist, but there are many others for you to explore!</p>
        
        {BrowsingOptions()}

      </div>
    );
  };
  
  export default Error404;
  