import React from "react";
import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import Spinner from "../components/ui/Spinner";
import LatestBlogPosts from "../components/LatestBlogPosts";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const BLOGPOST = gql`
  query GetBlogPosts($id: ID!) {
    blog(id: $id) {
      id
      published_at
      date
      blog_tags {
        tag
        id
      }
      content
      title
      rating
    }
  }
`;

function getParsedDate(date){
  var dateObj = String(date).split('-');
  var day = ("0" + dateObj[2]).substr(-2);
  var month = ("0" + dateObj[1]).substr(-2);
  return day + '-' + month + '-' + dateObj[0];
}

export default function BlogPost() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(BLOGPOST, {
    variables: { id: id },
  });

  if (loading)
    return (
      <p>
        <Spinner />
      </p>
    );
  if (error) return <p>Error :(</p>;
  // transformImageUri={uri => uri.startsWith("http") ? uri : `${process.env.REACT_IMAGE_BASE_URL}${uri}`}

  if (data.blog.date > new Date(data.blog.date).getDate() + 7) 
  {
    return (
      <div className="page-content center-text">
        <div className="waypoint-content">
          <p>
            I haven't gone there yet, but whilst waiting, you can check out
            everywhere I've been
          </p>
        </div>
        <Link to={"/waypoints/"} className="waypoint-btn previous all-waypoints-btn">
            All waypoints
          </Link>
      </div>
    );
  }

  return (
    <div className="page-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.blog.title}</title>
        <meta name="author" content="Bikepackingstories" />
        <meta name="description" content="A blog about long-distance biketouring" />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 blog-post-content">
            <h2 className="blog-post-title">{data.blog.title}</h2>
            <span className="center">
              {" "}
              <span className="licon icon-dat"></span> {getParsedDate(data.blog.date)}{" "}
              <span className="meta-seperator">|</span>{" "}
              <span className="licon icon-tag"></span>
              {data.blog.blog_tags.map((tag, i) => (
                
                <a key={tag.id} href={"/posts?" + tag.tag} className="meta-link">
                  {tag.tag}
                  {i !== data.blog.blog_tags.length - 1 && ", "}
                </a>

              ))}
            </span>
            <hr />

            <ReactMarkdown
              className="blog-section"
              transformImageUri={(uri) =>
                uri.startsWith("https")
                  ? uri
                  : `${"https://content.bikepackingstories.com"}${uri}`
              }
            >
              {data.blog.content}
            </ReactMarkdown>
          </div>
          <hr className="end-hr"/>
        </div>
        
      </div>
      
      <LatestBlogPosts amount={3} />
    </div>
  );
}
