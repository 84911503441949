import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";

function LatestBlogPosts({ amount }) {
  const BLOGS = gql`
                  query GetBlogs {
                      blogs(start: 0 , limit:${amount}, sort: "date:desc") {
                        id,
                        title,
                        subheading,
                        content,
                        blog_tags {id, tag},
                        featured_image {id, previewUrl, url, name, formats},
                        published_at, 
                        date
                      }
                    }`;

  const { loading, error, data } = useQuery(BLOGS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading latests blog posts</p>;

  return (
    <div className="container fp-section">
      <div className="row">
        {data.blogs.map((blogPost) => (
          <div key={blogPost.id} className="col-md">
            <article className="recipe">
              <div className="pizza-box">
                <img
                  src={'https://content.bikepackingstories.com' + blogPost.featured_image.formats.medium.url} width="1500" height="1368" alt={blogPost.featured_image.alternativeText}
                />
                
              </div>
              <div className="recipe-content">
                <p className="recipe-tags">
                  {blogPost.blog_tags.map((tag) => (
                    <Link
                      className="recipe-tag"
                      to={`/posts?categories=${tag.tag}`}
                    >
                      <FontAwesomeIcon
                        className="latest-tag-icon"
                        icon={faTag}
                      ></FontAwesomeIcon>
                      {tag.tag}
                    </Link>
                  ))}
                </p>
                <h1 className="recipe-title"><Link to={`/post/${blogPost.id}`}>{blogPost.title}</Link></h1>
                <hr />
                <p className="recipe-desc">{(blogPost.subheading !== null) ? blogPost.subheading : blogPost.content.substring(0, 200) + "..."}</p>
              
              </div>
              <Link to={`/post/${blogPost.id}`} className="waypoint-btn btn m-5 mb-3 mt-2">
                  See post
              </Link>
            </article>
          </div>
        ))}
      </div>
    </div>
  );

}

export default LatestBlogPosts;
