import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import mainLogo from '../img/mainLogo.png'


export class ScrollApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageSize: 9,
      currentPageNumber: 0,
      items: [],
      hasMore: true,
      newDate: new Date(new Date().setDate(new Date().getDate())),
      count: 0,
      adventure: this.props.adventure
    };
  }
  
  getParsedDate(date){
    var dateObj = String(date).split('-');
    var day = ("0" + dateObj[2]).substr(-2);
    var month = ("0" + dateObj[1]).substr(-2);
    return day + '-' + month + '-' + dateObj[0];
  }

  componentDidMount() {
    this.fetchMoreData();
  }

  fetchMoreData = async () => {
    // let queryLimitDate = `${this.state.newDate.getFullYear()}-${(this.state.newDate.getMonth() +1)<10?`0${(this.state.newDate.getMonth() +1)}`:`${(this.state.newDate.getMonth() +1)}`}-${(this.state.newDate.getDate())<10?`0${(this.state.newDate.getDate())}`:`${(this.state.newDate.getDate())}`}`; 
    let pageNum = this.state.currentPageNumber + Math.floor(this.state.currentPageNumber / 2);
    let endPageNum = this.state.currentPageNumber + 1;
    // const result = await axios.get(`https://content.bikepackingstories.com/waypoints?date_lte=${queryLimitDate}&_sort=date:DESC&_start=${pageNum * this.state.currentPageSize}&_limit=${this.state.currentPageSize * endPageNum}`);
    const result = await axios.get(`https://content.bikepackingstories.com/waypoints?_sort=date:DESC&_start=${pageNum * this.state.currentPageSize}&_limit=${this.state.currentPageSize * endPageNum}&adventure.adventure=${this.state.adventure}`);
    
    console.log("Fetched " + pageNum);
    
    
    this.setState({
      currentPageNumber: pageNum + 1
    })


    console.log("Set new page number to " + this.state.currentPageNumber);

    this.setState({
      items: this.state.items.concat(result.data)
    });

  };

  render() {
    return (

      <InfiniteScroll
        dataLength={this.state.items.length}
        next={this.fetchMoreData}
        hasMore={this.state.hasMore}
        className="page-content"
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>You've reached the end. You have seen it all!</b>
          </p>
        }
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
        
      >
        {this.state.items.map((waypoint) => (
          <article className="postcard light yellow">
            <Link className="postcard__img_link" to={`/waypoint/${waypoint.id}`}>
              {(waypoint.images.length !== 0) ? <img className="image" src={"https://content.bikepackingstories.com" + waypoint.images[0]["formats"]["large"]["url"]} alt={waypoint.images[0]["alternativeText"]} /> : <img className="image" src={mainLogo} alt="Bikepackingstories" />}
            </Link>
            <div className="postcard__text t-dark">
              <h1 className="postcard__title yellow">
                <Link to={`/waypoint/${waypoint.id}`}>{waypoint.title}</Link>
              </h1>
              <div className="postcard__subtitle small">
                <time dateTime="2020-05-25 12:00:00">
                  <i className="fas fa-calendar-alt mr-2"></i>{this.getParsedDate(waypoint.date)}
                </time>
                <time className="waypoint-sep">|</time>
                <time>{waypoint.distance}km</time>
              </div>
              <div className="postcard__bar"></div>
              <ReactMarkdown className="postcard__preview-txt">{waypoint.subheading !== null ? waypoint.subheading : (waypoint.description !== null && waypoint.description.substring(0, 240)) }</ReactMarkdown>
              <div className="postcard__tagbox">
                <Link className="waypoint-btn small-btn" to={`/waypoint/${waypoint.id}`}>See entry</Link>
              </div>
            </div>
          </article>
        ))}
      </InfiniteScroll>
    );
  }
}

export default ScrollApp;
