import {ScrollApp} from '../components/InifiniteScroll'
import Select from "react-select";
import { useQuery, gql } from "@apollo/client";
import { useState, useMemo, useEffect } from 'react';


const ADVENTURES = gql`
query GetAdventures {
  adventures {
    id
    adventure
    marker_color
  }
}
`;


function handleRedirect(param) {
  const url = 'https://bikepackingstories.com/waypoints';
  // const url = 'http://localhost:3000/waypoints';
  const params = {
    tour: param
  };
  const queryString = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
  window.location.href = `${url}?${queryString}`;
};


function getParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const tour = urlParams.get('tour');
  if (tour === null) {
    return { value: 'World Tour', label: 'World Tour' };
  } else {
    return { value: tour, label: tour };
  }
};

  


const Waypoints = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { loading, error, data } = useQuery(ADVENTURES);
  const [isLoading, setIsLoading] = useState(true);

  const options = useMemo(() => {
    const newOptions = [];

    if (data !== undefined) {
      data.adventures.forEach((adventure) => {
        newOptions.push({ value: adventure.adventure, label: adventure.adventure });
      });
    }

    return newOptions;
  }, [data]);

  const onChange = (q) => {
    setSelectedOption(q);
    handleRedirect(q.label);
  };

  useEffect(() => {
    setSelectedOption(getParams());
  }, []);
  
  useEffect(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [loading]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading categories dropdown</p>;
  }

  return (
    <div className="container page-content">
      <div className="row">
        <div className="col-12 waypoints-intro">
          <h2>Waypoints</h2>
          <p>
            Each day I save the coordinates of my current location, as well as the distance ridden that day. The aim is
            to create the most comprehensive, chronological timeline of a two-wheeled globe circumvention. For all the
            places I've been; Here is my timeline:
          </p>
          <Select
            className="basic-single"
            classNamePrefix="select"
            name="tours"
            options={options}
            value={selectedOption}
            onChange={(e) => onChange(e)}
            placeholder="Filter by tour"
          />
        </div>
        <div className="col-12">
          <ScrollApp adventure={selectedOption.value} />
        </div>
      </div>
    </div>
  );
};

export default Waypoints;
