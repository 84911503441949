import { Link } from "react-router-dom";
import banner_BPS from '../../img/banner_BPS.jpg' // Import the banner image

const SeeMapBanner = () => {
  return (
    // Return a single img element with the banner image as the src
    <img src={banner_BPS} alt="See map" className="see-map-banner"/>
  );
}
 
export default SeeMapBanner;