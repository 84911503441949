import React from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";


class CurrentLocation extends React.Component {
    constructor(props) {
      super(props);
      this.listener = null;

      this.state = {
        error: null,
        isLoaded: false,
        items: [],
        status: "top"
      };
    }
  
    componentDidMount() {
      // Get the current location
      axios.get("https://cutcard.net/api/bikepackingstories/currentlocation")
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              items: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )

        // Scroll handling
        
        this.listener = document.addEventListener("scroll", e => {
          var scrolled = document.scrollingElement.scrollTop;
          if (scrolled >= 120) {
            if (this.state.status !== "amir") {
              this.setState({ status: "amir" });
            }
          } else {
            if (this.state.status !== "top") {
              this.setState({ status: "top" });
            }
          }
        });
    }

    componentDidUpdate() {
      document.removeEventListener("scroll", this.listener);
    }
  
    render() {
      const { error, isLoaded, items } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
        return (
            <div className={this.state.status === "top" ? "current-location-container" : "current-location-container location-scrolled"}>
            <span className="current-location-explainer">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="current-location-icon"
              ></FontAwesomeIcon>
              <p className="location-text-small">Currently in:</p>
            </span>

            {items["data"].map(item => (
              <div className="current-location-small" key={item.key}>
                {item.value}
              </div>
            ))}

        </div>
        );
      }
    }
  }

  export default CurrentLocation;