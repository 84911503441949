import ContactForm from "../components/ui/ContactForm";

const Contact = () => {
  return (
    <div className="page-content">
      <h1 style={{'text-align' : 'center'}}>Want to get in touch?</h1>
      <p style={{'text-align' : 'center'}}>Just leave your message and details below and hit submit</p>
      <ContactForm />
    </div>
  );
};

export default Contact;
