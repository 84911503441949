import React from "react";
import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useState } from "react";

const WAYPOINT = gql`
  query GetWaypoints($id: ID!) {
    waypoint(id: $id) {
      latitude
      longitude
      adventure {
        adventure
      }
      description
      distance
      date
      title
      id
      blog {
        id
        content
        title
      }
      images {
        previewUrl
        id
        url
        name
      }
    }
  }
`;

function getParsedDate(date){
  var dateObj = String(date).split('-');
  var day = ("0" + dateObj[2]).substr(-2);
  var month = ("0" + dateObj[1]).substr(-2);
  return day + '-' + month + '-' + dateObj[0];
}

export default function Waypoint() {
  const { id } = useParams();
  const newDate = new Date(new Date().setDate(new Date().getDate()));
  const [nextId, setNextId] = useState(Number(id) + 1);
  const [previousId, setpreviousId] = useState(Number(id) - 1);
  const { loading, error, data } = useQuery(WAYPOINT, {
    variables: { id: id },
  });

  let queryLimitDate = `${newDate.getFullYear()}-${(newDate.getMonth() +1)<10?`0${(newDate.getMonth() +1)}`
      :`${(newDate.getMonth() +1)}`}-${(newDate.getDate())<10?`0${(newDate.getDate())}`:`${(newDate.getDate())}`}`; 

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading page</p>;

    const fetchNextId = async () => {
      const result = await axios.get(`https://content.bikepackingstories.com/waypoints?date_lte=${queryLimitDate}&_sort=date:ASC&adventure.adventure=${data.waypoint.adventure.adventure}`);
      var idOrder = result.data.map(waypoint => waypoint.id); 
      var payload = idOrder[idOrder.indexOf(Number(id)) + 1]; 
      payload ? setNextId(payload) : setNextId(null);
    };

    const fetchPrevId = async () => {
      const result = await axios.get(`https://content.bikepackingstories.com/waypoints?date_lte=${queryLimitDate}&_sort=date:ASC&adventure.adventure=${data.waypoint.adventure.adventure}`);
      var idOrder = result.data.map(waypoint => waypoint.id); 
      var payload = idOrder[idOrder.indexOf(Number(id)) - 1];
      payload ? setpreviousId(payload) : setpreviousId(null);
    };

    fetchNextId();
    fetchPrevId();
  if (data.waypoint === null)
    return (
      <div className="page-content center-text">
        <div className="waypoint-content">
          <p>
            I haven't gone there yet, but you whilst waiting, you can check out
            everywhere I've been
          </p>
        </div>
        <Link to={"/waypoints/"} className="waypoint-btn previous all-waypoints-btn">
            All waypoints
          </Link>
      </div>
    );

  return (
    <div className="page-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.waypoint.title}</title>
        <meta name="description" content="A day-by-day journal of a bicycletrip around the globe" />
      </Helmet>
      <div className="waypoint-content">
        <div className="waypoint-metadata">
          <h2>{data.waypoint.title} </h2>
          <ul>
            <li>Distance: {data.waypoint.distance}km</li>
            <li className="waypoint-meta-sep">|</li>
            <li>Date: {getParsedDate(data.waypoint.date)}</li>
          </ul>
          <hr className="sep-line" />
        </div>

        {data.waypoint.images.length > 0 && 
        <img src={"https://content.bikepackingstories.com" + data.waypoint.images[0]["url"]} alt={data.waypoint.images[0]["name"]} className="waypoint-image" />
        }

        <ReactMarkdown>{data.waypoint.description}</ReactMarkdown>
        <div className="waypoint-nav">
          {previousId && (
            <Link
              to={`/waypoint/${previousId}`}
              className="waypoint-btn previous"
            >
              &laquo; Previous waypoint
            </Link>
          )}
          { nextId &&
            <Link to={`/waypoint/${nextId}`} className="waypoint-btn next">
              Next waypoint &raquo;
            </Link>
          }
        </div>
      </div>
    </div>
  );
}
