import { useState, useEffect } from 'react';
import axios from 'axios';
import Spinner from '../components/ui/Spinner'
import ReactMarkdown from 'react-markdown';

const OurStory = () => {
  const [currentContent, setCurrentContent] = useState(null);
  const [currentFeaturedImage, setCurrentFeaturedImage] = useState(null); 
  const [currentAltText, setCurrentAltText] = useState(null); 


  async function UpdateCurrentContent() {
    const result = await axios.get(`https://content.bikepackingstories.com/our-story-sections`);
    setCurrentContent(result.data)
  }

  async function UpdateCurrentFeaturedImage() {
    const result = await axios.get(`https://content.bikepackingstories.com/our-story-image`);
    setCurrentFeaturedImage('https://content.bikepackingstories.com' + result.data['image']["formats"]["small"]['url']);
    setCurrentAltText(result.data['image']['alternativeText']);
  }

  useEffect(() => {
    UpdateCurrentContent();
    UpdateCurrentFeaturedImage();
  }, []);
    
  return (
    <div className="page-content container">
      <div className="about-container row">
        <div className="col-12 about-image">
            <img src={currentFeaturedImage} alt={currentAltText}/>
        </div>
        <div className="col-lg-9 col-md-8 col-sm-12 centered">
          {currentContent !== null ? currentContent.map((c) => 
            <div className='row mt-5'>
            <h2>{c['section_title']}</h2>
            <div className='mt-4'>
              <ReactMarkdown>
              {c['section_content']}
              </ReactMarkdown>
              </div>
            </div>
          ) : <p><Spinner/></p>}
          <br/>
          <p style={{textAlign: "center" }}>See you on the road! </p>
          <p style={{textAlign: "center" }}>Andreas</p>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
