import { useQuery, gql} from "@apollo/client"
import Spinner from '../ui/Spinner'

    const IMAGE = gql`
    query GetImage {
        frontpageimage {
          id,
          image {url, previewUrl, formats},
          description,
          title
        }
      }`

const FrontpageFeaturedImage = () => {
    const { loading, error, data } = useQuery(IMAGE);
    return loading ? (
        <Spinner />
      ) : !error ? ( 
    <div className="frontpage-faetured-image" style={{textAlign: 'center'}}>
        <img src={"https://content.bikepackingstories.com" + data.frontpageimage.image.url} alt="Frontpage bicycle banner"/>
        <h3 className="frontpage-title">{data.frontpageimage.title}</h3>
        <p className="frontpage-subtitle">{data.frontpageimage.description}</p>
    </div> ) : <div></div>;
}
 
export default FrontpageFeaturedImage;